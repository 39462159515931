import {
  Account,
  Customer,
  EntityType,
  RoleType,
  ServiceProvider,
} from "../types/index.js";

export const service_providers: ServiceProvider[] = [
  {
    id: 1,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzX2mQpzk0A9hsidyn1G-_Xv84Ph-N5UWWqg&s",
    name: "SLC Bookkeeping",
    domain: "slcbookkeeping.com",
    slug: "slcbookkeeping",
    primaryColor: "blue",
    initial: "SLC",
    notifications: 120,
  },
  {
    id: 2,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgfObHmd-rRg-KbQojcyaMM_1P7KR2u8K-9Q&s",
    name: "Restaurant Refinery",
    domain: "restaurantrefinery.com",
    slug: "restaurantrefinery",
    primaryColor: "pink",
    initial: "RR",
    notifications: 32,
  },
  {
    id: 3,
    avatar:
      "https://cdn.dribbble.com/users/839534/screenshots/6214024/payroll_logo-01_4x.jpg",
    name: "Bob's Payroll",
    domain: "bobspayroll.com",
    slug: "bobspayroll",
    primaryColor: "green",
    initial: "BP",
    notifications: 81,
  },
];

export const customers: Customer[] = [
  {
    id: 1,
    avatar:
      "https://visitdefianceohio.com/wp-content/uploads/2024/05/428369595_122111301614207499_6853239776791924193_n-500x496.jpg",
    name: "Jack's Kitchen",
    domain: "jackskitchen.com",
    slug: "jackskitchen",
    primaryColor: "orange",
    initial: "JK",
    providerId: 2,
    location: "Salt Lake City, UT",
    notifications: 20,
  },
  {
    id: 2,
    avatar:
      "https://myareanetwork-photos.s3.amazonaws.com/bizlist_photos/f/255425_1523243488.jpg?0",
    name: "Las Marias B'Ville",
    domain: "lasmariasmissoula.com",
    slug: "lasmarias",
    primaryColor: "teal",
    initial: "LM",
    providerId: 2,
    location: "Missoula, MT",
    notifications: 30,
  },
  {
    id: 3,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsg70QbbSw-9ks8Rqxh7j8E58u8RY51dPy9Q&s",
    name: "Henry's Pizza",
    domain: "hpizzabville.com",
    slug: "henryspizza",
    primaryColor: "yellow",
    initial: "HP",
    providerId: 2,
    location: "Bentonville, AR",
    notifications: 13,
  },
  {
    id: 4,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsg70QbbSw-9ks8Rqxh7j8E58u8RY51dPy9Q&s",
    name: "Trampolines USA",
    domain: "trampolinesusa.com",
    slug: "trampolinesusa",
    primaryColor: "pink",
    initial: "TUS",
    providerId: 1,
    location: "Bentonville, AR",
    notifications: 74,
  },
  {
    id: 5,
    avatar:
      "https://myareanetwork-photos.s3.amazonaws.com/bizlist_photos/f/255425_1523243488.jpg?0",
    name: "Las Maria Faye",
    domain: "lasmariasmissoula.com",
    slug: "lasmarias",
    primaryColor: "teal",
    initial: "LM",
    providerId: 2,
    location: "Missoula, MT",
    notifications: 10,
  },
];

export const accounts: Account[] = [
  {
    sub: "1",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Ashley Mulligan",
    nickname: "ashley",
    email: "ashley@slcbookkeeping.com",
    providerId: 1,
    roles: [
      {
        entityType: EntityType.ServiceProvider,
        entityId: 1,
        role: RoleType.Admin,
      },
      {
        entityType: EntityType.Customer,
        entityId: 4,
        role: RoleType.Admin,
      },
    ],
    notifications: 20,
    sessionId: 2,
  },
  {
    sub: "2",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Ashley Mulligan",
    nickname: "ashley",
    email: "ashley@restaurantrefinery.com",
    providerId: 2,
    roles: [
      {
        entityType: EntityType.ServiceProvider,
        entityId: 2,
        role: RoleType.User,
      },
    ],
    notifications: 5,
    sessionId: 3,
  },
  {
    sub: "3",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Ashley Mulligan",
    nickname: "ashley",
    email: "ashley@bobspayroll.com",
    providerId: 3,
    roles: [
      {
        entityType: EntityType.ServiceProvider,
        entityId: 3,
        role: RoleType.Admin,
      },
    ],
    notifications: 32,
    sessionId: 4,
  },
  {
    sub: "4",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Ashley Mulligan",
    nickname: "ashley",
    email: "ashley@hellooperator.com",
    roles: [
      {
        entityType: EntityType.Account,
        entityId: 4,
        role: RoleType.Admin,
      },
    ],
    notifications: 13,
    sessionId: 5,
  },
  {
    sub: "5",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Ashley Mulligan",
    nickname: "ashley",
    email: "ashley@refractventures.co",
    roles: [
      {
        entityType: EntityType.Account,
        entityId: 5,
        role: RoleType.Admin,
      },
    ],
    notifications: 0,
    sessionId: 6,
  },
  {
    sub: "google-oauth2|117005725451820019956",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Ashley Mulligan",
    nickname: "ashley",
    email: "ashley@ledger.ai",
    roles: [
      {
        entityType: EntityType.System,
        entityId: 0,
        role: RoleType.SuperAdmin,
      },
    ],
    notifications: 11,
    sessionId: 0,
  },
  {
    sub: "google-oauth2|116665040145435919584",
    picture:
      "https://lh3.googleusercontent.com/a/ACg8ocJU1t9liAfIHo4qPgAwkdVck4FVF0aA0zvb-Z60UH22KY_431w=s96-c",
    name: "Penny Mulligan",
    nickname: "penny",
    email: "penny@ledger.ai",
    roles: [
      {
        entityType: EntityType.System,
        entityId: 0,
        role: RoleType.SuperAdmin,
      },
    ],
    notifications: 11,
    sessionId: 1,
  },
];
