import {
  AppShell,
  Card,
  List,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  UnstyledButton,
  rem,
} from "@mantine/core";
import {
  IconCheck,
  IconNumber1,
  IconNumber2,
  IconNumber3,
} from "@tabler/icons-react";

interface WelcomeGuideProps {
  page: number;
  setInputAndCallWebhook: (value: string) => void; // Pass the function as a prop
}

export function GuideContent({
  page,
  setInputAndCallWebhook,
}: WelcomeGuideProps) {
  return (
    <AppShell.Section grow component={ScrollArea} ta="left">
      <Stack p={20}>
        {page === 1 && (
          <>
            <Text size="md" fw={600}>
              Managing your tasks can be overwhelming, especially when juggling
              multiple platforms, as traditional tools often leave you with
              incomplete information and more questions.
            </Text>
            <Text size="md">
              That&apos;s why Penny exists— she&apos;s your supercharged
              assistant. With LedgerAI's natural language processing, you can
              perform tasks using simple, conversational language—right here or
              via Slack.
            </Text>
            <Text size="md">
              Whether you need to onboard a new employee, get real-time
              financial insights, or receive proactive notifications, Penny has
              you covered.
            </Text>
            <Text size="md">
              <Text span fw={600}>
                Get started
              </Text>{" "}
              by choosing a prompt or asking your own question:
            </Text>

            <UnstyledButton
              onClick={() =>
                setInputAndCallWebhook(
                  "In Toledo, Ohio, what payroll taxes do I need to be aware of when hiring a new employee?"
                )
              }
            >
              <Card withBorder radius="sm">
                <Text size="lg" fw={600}>
                  I’m hiring a new employee...
                </Text>
                <Text size="sm">
                  in Toledo, Ohio. What payroll taxes do I need to be aware of?
                </Text>
              </Card>
            </UnstyledButton>

            <UnstyledButton
              onClick={() =>
                setInputAndCallWebhook(
                  "What payroll taxes do I need to be aware of when I hire an employee in Toledo, Ohio?"
                )
              }
            >
              <Card withBorder radius="sm">
                <Text size="lg" fw={600}>
                  Send me new labor laws...
                </Text>
                <Text size="sm">
                  or any proposed changes at the federal level.
                </Text>
              </Card>
            </UnstyledButton>

            <UnstyledButton
              onClick={() =>
                setInputAndCallWebhook(
                  "Send me new labor laws or any proposed changes at the federal level."
                )
              }
            >
              <Card withBorder radius="sm">
                <Text size="lg" fw={600}>
                  Using Gusto, how do I...
                </Text>
                <Text size="sm">map transactions to my QuickBooks?</Text>
              </Card>
            </UnstyledButton>

            <UnstyledButton
              onClick={() =>
                setInputAndCallWebhook(
                  "What happens if I make a late withholding payment to Texas as an employer?"
                )
              }
            >
              <Card withBorder radius="sm">
                <Text size="lg" fw={500}>
                  As an employer...
                </Text>
                <Text size="sm">
                  what happens if I make a late withholding payment to Texas?
                </Text>
              </Card>
            </UnstyledButton>
          </>
        )}
        {page === 2 && (
          <>
            <Text>
              Penny offers a range of expertise, helping you streamline tasks
              with ease:
            </Text>
            <List
              size="sm"
              spacing="md"
              icon={
                <ThemeIcon variant="transparent" size={24} radius="xl">
                  <IconCheck
                    stroke={2}
                    style={{ width: rem(24), height: rem(24) }}
                  />
                </ThemeIcon>
              }
            >
              <List.Item>
                <Text size="md" fw={600}>
                  Accounting & Payroll Expertise
                </Text>
                Navigates U.S. payroll, labor laws, and tax compliance.
              </List.Item>
              <List.Item>
                <Text size="md" fw={600}>
                  Software Support
                </Text>
                Integrates with Gusto, Bill.com, and QuickBooks.
              </List.Item>
              <List.Item>
                <Text size="md" fw={600}>
                  Guidance & Support
                </Text>
                Provides clear explanations to your questions.
              </List.Item>
              <List.Item>
                <Text size="md" fw={600}>
                  Up-to-Date Information
                </Text>
                Delivers the latest payroll laws and news.
              </List.Item>
              <List.Item>
                <Text size="md" fw={600}>
                  Quick Calculations
                </Text>
                Performs instant computations with a built-in calculator.
              </List.Item>
            </List>
          </>
        )}
        {page === 3 && (
          <>
            <Text>To get the most out of Penny:</Text>
            <List type="ordered" size="sm" spacing="md">
              <List.Item
                icon={
                  <ThemeIcon variant="outline" size={24} radius="xl">
                    <IconNumber1
                      stroke={2}
                      style={{ width: rem(16), height: rem(16) }}
                    />
                  </ThemeIcon>
                }
              >
                <Text size="md" fw={600}>
                  Ask clear, specific questions
                </Text>
                Penny may ask for details to provide the best answer.
              </List.Item>
              <List.Item
                icon={
                  <ThemeIcon variant="outline" size={24} radius="xl">
                    <IconNumber2
                      stroke={2}
                      style={{ width: rem(16), height: rem(16) }}
                    />
                  </ThemeIcon>
                }
              >
                <Text size="md" fw={600}>
                  Introduce yourself
                </Text>
                Share your name, job title, and workplace for personalized
                support.
              </List.Item>
              <List.Item
                icon={
                  <ThemeIcon variant="outline" size={24} radius="xl">
                    <IconNumber3
                      stroke={2}
                      style={{ width: rem(16), height: rem(16) }}
                    />
                  </ThemeIcon>
                }
              >
                <Text size="md" fw={600}>
                  Create a free account
                </Text>
                Penny stores session info to learn and improve over time. No
                credit card needed!
              </List.Item>
            </List>
          </>
        )}
      </Stack>
    </AppShell.Section>
  );
}
