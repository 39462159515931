import {
  Avatar,
  Badge,
  Box,
  Button,
  Group,
  Menu,
  MenuItem,
  MenuLabel,
  ScrollArea,
  Title,
  UnstyledButton,
} from "@mantine/core";
import { IconChevronDown, IconCircleCheckFilled } from "@tabler/icons-react";
import { useState } from "react";

import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import useFilteredCustomers from "../../hooks/useFilteredCustomers.js";
import { Customer } from "../../types/index.js";
import CustomerModal from "../Modals/CustomerModal.js";

const CustomerMenu = () => {
  const { activeCustomer, setActiveCustomer, activeServiceProvider } =
    useActiveStates();

  const filteredCustomers = useFilteredCustomers();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer[]>(filteredCustomers);

  // Function to handle form submission from the modal
  const handleCustomerSubmit = (newCustomer: Partial<Customer>) => {
    const newId =
      customers.length > 0 ? Math.max(...customers.map((c) => c.id)) + 1 : 1;

    const customer: Customer = {
      id: newId,
      name: newCustomer.name as string,
      domain: newCustomer.domain as string,
      slug: newCustomer.slug as string,
      primaryColor: newCustomer.primaryColor as string,
      initial: newCustomer.initial as string,
      providerId: newCustomer.providerId ?? 0,
      location: newCustomer.location as string,
      avatar: newCustomer.avatar as string,
      notifications: 0,
    };

    setCustomers((prev) => [...prev, customer]);
    setActiveCustomer(customer);
  };

  return (
    <>
      <Menu withArrow shadow="md" opened={menuOpened} onChange={setMenuOpened}>
        <Menu.Target>
          <UnstyledButton>
            <Group gap={5}>
              <Avatar
                variant="filled"
                mr="lg"
                size={45}
                radius="sm"
                color={activeCustomer?.primaryColor}
              >
                {activeCustomer?.initial}
              </Avatar>
              <Title order={4}>{activeCustomer?.name || "LedgerAI"}</Title>
              <IconChevronDown width={20} />
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <MenuLabel>{activeServiceProvider?.name} Customers</MenuLabel>
          <ScrollArea mah={280}>
            {filteredCustomers.length > 0 ? (
              filteredCustomers.map((customer) => (
                <MenuItem
                  key={customer.id}
                  onClick={() => setActiveCustomer(customer)}
                  leftSection={
                    <Avatar
                      variant="filled"
                      size={30}
                      alt={customer.name}
                      color={customer.primaryColor}
                      radius="sm"
                    >
                      {customer.initial}
                    </Avatar>
                  }
                  rightSection={
                    customer.id === activeCustomer?.id ? (
                      <IconCircleCheckFilled color="#00ba7c" size={19} />
                    ) : (
                      <Badge>{customer.notifications}</Badge>
                    )
                  }
                >
                  {customer.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No customers yet</MenuItem>
            )}
          </ScrollArea>
          <Menu.Divider />
          <Box m="lg">
            <Button
              size="xs"
              variant="light"
              fullWidth
              onClick={() => {
                setModalOpened(true);
              }}
            >
              Add a customer
            </Button>
          </Box>
        </Menu.Dropdown>
      </Menu>

      {/* Render the CustomerModal */}
      <CustomerModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        onSubmit={handleCustomerSubmit}
        activeServiceProvider={activeServiceProvider}
      />
    </>
  );
};

export default CustomerMenu;
