import { createTheme, MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = createTheme({
  /* Put your mantine theme override here */

  fontFamily: "Plus Jakarta Sans, sans-serif",
  fontFamilyMonospace: "PT Mono",
  primaryColor: "dark",
  components: {
    Modal: {
      styles: (theme: MantineThemeOverride) => ({
        title: {
          fontWeight: 700,
          fontSize: theme.fontSizes?.lg,
          color: theme.colors?.dark?.[0] ?? "#000000",
        },
      }),
    },

    Menu: {
      styles: (theme: MantineThemeOverride) => ({
        dropdown: {
          padding: theme.spacing?.sm + " 0",
          borderRadius: theme.radius?.lg,
        },
        item: {
          borderRadius: 0,
          padding: theme.spacing?.xs + " " + theme.spacing?.md,
          color: theme.colors?.dark?.[0] ?? "#000000", // Dark text color with fallback
          fontSize: theme?.fontSizes?.sm, // You can use theme's font sizes
          fontWeight: 700, // Medium weight
          "&:hover": {},
          "&:active": {},
        },
        label: {
          padding: theme.spacing?.xs + " " + theme.spacing?.md,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          fontSize: "0.6rem",
          color: theme.colors?.dark?.[0] ?? "#000000",
        },
      }),
    },
  },
  colors: {
    softCream: [
      "#FFFDFB", // Lightest shade
      "#FFFDF9",
      "#FFFDFB",
      "#FFFDF9",
      "#FFFCF6",
      "#FFFBF3",
      "#FFF8E7",
      "#FFF8E7",
      "#FFF8E7",
      "#FFF8E7", // Darkest shade
    ],
    burntSienna: [
      "#FFF0E0",
      "#FFE1CC",
      "#FFD1B8",
      "#FBB99E",
      "#E7A184",
      "#D3896A",
      "#BF7150",
      "#BF7150",
      "#BF7150",
      "#BF7150",
    ],
    mutedTeal: [
      "#C6FBFF",
      "#B0F4F8",
      "#9AEDF1",
      "#86D8DB",
      "#72C3C5",
      "#5EAEAF",
      "#4A999C",
      "#4A999C",
      "#4A999C",
      "#4A999C",
    ],
    slateBlue: [
      "#D9E7FA", // Lightest shade
      "#C4D8F3",
      "#AFC9EC",
      "#9BB2D4",
      "#879ABC",
      "#7382A3",
      "#5F6A8A",
      "#5F6A8A",
      "#5F6A8A",
      "#5F6A8A", // Darkest shade
    ],
    dark: [
      "#231F20",
      "#45393C",
      "#665356",
      "#89706F",
      "#AB8E89",
      "#34354a",
      "#2b2c3d",
      "#1d1e30",
      "#0c0d21",
      "#01010a",
    ],
    gray: [
      "#F0F3FF", // Lightest shade
      "#F0F3FF",
      "#F0F3FF",
      "#F0F3FF",
      "#9093A1",
      "#787A86",
      "#60656F",
      "#484D54",
      "#30343B",
      "#181A1F", // Darkest shade
    ],
  },
  white: "#FFFDFB",
  black: "#231F20",
});
