import { Button, Container, Group, Stack, Text } from "@mantine/core";

import { IconPlus, IconQuestionMark, IconTestPipe } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { useUser } from "../../contexts/UserProfileContext.js";
import {
  isCustomerAdmin,
  isFreeAccount,
  isServiceProviderAdmin,
} from "../../helpers/roleUtils.js";
import {
  capitalizeFirstLetter,
  getEmailDomain,
} from "../../helpers/textUtils.js";
import classes from "./welcome.css.js";

export function Welcome() {
  const user = useUser();
  const { activeAccount, activeCustomer, activeServiceProvider } =
    useActiveStates();

  const canAuthenticate = activeCustomer
    ? isCustomerAdmin(activeAccount, activeCustomer.id)
    : isFreeAccount(activeAccount)
    ? true
    : false;

  const greetingName = user.nickname || user.name?.split?.(" ")?.[0] || "Hello";

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      <div className={classes.wrapper}>
        <Container>
          <Stack gap={10}>
            <h1 className={classes.title}>
              Welcome{" "}
              <Text component="span" c={activeCustomer?.primaryColor} inherit>
                {capitalizeFirstLetter(greetingName)}
              </Text>
              ,
            </h1>

            <Text className={classes.description} color="dimmed">
              {canAuthenticate
                ? `Let's begin by connecting the essential third-party services for ${
                    activeCustomer?.name || getEmailDomain(activeAccount?.email)
                  }. Meanwhile, Penny is here to answer any of your questions about finance and accounting.`
                : `Ask Penny a question about ${
                    activeCustomer?.name || getEmailDomain(activeAccount?.email)
                  }, or explore recipes aimed to automate your bookkeeping tasks.`}
            </Text>

            <Group>
              {canAuthenticate && (
                <Link to="/connect/overview">
                  <Button
                    size="lg"
                    radius="xl"
                    color={activeCustomer?.primaryColor}
                  >
                    Get connected
                  </Button>
                </Link>
              )}
              {activeServiceProvider &&
                !activeCustomer &&
                isServiceProviderAdmin(
                  activeAccount,
                  activeServiceProvider.id
                ) && (
                  <Button
                    radius="xl"
                    size="lg"
                    leftSection={<IconPlus />}
                    color={activeServiceProvider?.primaryColor}
                  >
                    Add a customer
                  </Button>
                )}
              <Link to="/penny/overview">
                <Button
                  size="lg"
                  radius="xl"
                  variant="light"
                  color={activeCustomer?.primaryColor}
                  leftSection={<IconQuestionMark />}
                >
                  Ask Penny a question
                </Button>
              </Link>
              {activeCustomer && activeServiceProvider && (
                <Link to="/recipes/overview">
                  <Button
                    size="lg"
                    radius="xl"
                    variant="subtle"
                    color={activeCustomer?.primaryColor}
                    leftSection={<IconTestPipe size={20} />}
                  >
                    See all your recipes
                  </Button>
                </Link>
              )}
            </Group>
          </Stack>
        </Container>
      </div>
    </Layout>
  );
}
