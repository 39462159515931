import { Grid, Container } from "@mantine/core";
import { ArticleCardImage } from "../../components/Cards/ArticleCardImage.js";
import SlackButton from "../../components/Buttons/SlackButton.js";
import QuickbooksButton from "../../components/Buttons/QuickbooksButton.js";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
// import GustoButton from "../../components/Buttons/GustoButton.js";

const cardData = [
  {
    id: 1,
    backgroundColor: "#14324f",
    logo: "/images/quickbooks.svg",
    title: "Quickbooks Online",
    scope: "com.intuit.quickbooks.accounting",
    span: { xs: 12, sm: 12, md: 6 },
    link: "../connect/recipe-1",
    comingsoon: false,
    button: <QuickbooksButton />,
  },
  // {
  //   id: 2,
  //   backgroundColor: "#f45d48",
  //   logo: "/images/gusto.svg",
  //   title: "Gusto",
  //   scope: "",
  //   span: { xs: 12, sm: 4 },
  //   link: "../connect/recipe-1",
  //   comingsoon: false,
  //   button: <GustoButton />,
  // },
  {
    id: 3,
    backgroundColor: "#4A154B",
    logo: "/images/slack.svg",
    title: "Slack",
    span: { xs: 12, sm: 12, md: 6 },
    link: "#",
    comingsoon: false,
    button: <SlackButton />,
  },
  {
    id: 4,
    backgroundColor: "#14324f",
    logo: "/images/quickbooks.svg",
    title: "Quickbooks Online",
    scope: "com.intuit.quickbooks.payment",
    span: { xs: 12, sm: 12, md: 6 },
    link: "../connect/recipe-1",
    comingsoon: true,
  },
  {
    id: 6,
    backgroundColor: "#14324f",
    logo: "/images/quickbooks.svg",
    title: "Quickbooks Online",
    scope: "com.intuit.quickbooks.payroll.timetracking",
    span: { xs: 12, sm: 12, md: 6 },
    link: "../connect/recipe-1",
    comingsoon: true,
  },
  // {
  //   id: 4,
  //   backgroundColor: "#4285f4",
  //   logo: "/images/gdrive.svg",
  //   title: "Google Drive",
  //   span: { xs: 12, sm: 5 },
  //   link: "#",
  //   comingsoon: true,
  //   button: null,
  // },
];

export function ConnectOverview() {
  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      <Container fluid my="md" mb={80}>
        <Grid>
          {cardData.map((card, index) => (
            <Grid.Col span={card.span} key={index}>
              <ArticleCardImage
                id={card.id}
                scope={card.scope}
                backgroundColor={card.backgroundColor}
                logo={card.logo}
                title={card.title}
                link={card.link}
                comingsoon={card.comingsoon}
                button={card.button}
              />
            </Grid.Col>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
}
