type Primitive = string | number;

type RequestBody = Record<string, any>;

interface RequestOptions extends RequestInit {
  data?: RequestBody;
  params?: Record<string, Primitive>;
}

function urlWithParams(url: string, params?: Record<string, Primitive>) {
  const fake = "http://_";
  const isLocalUrl = url.startsWith("/");
  const fullUrl = isLocalUrl ? `${fake}${url}` : url;
  const finalUrl = new URL(fullUrl);
  if (params) {
    Object.entries(params).forEach(([k, v]) =>
      finalUrl.searchParams.append(
        k,
        typeof v === "string" ? v : v.toString(10)
      )
    );
  }
  const finalUrlPath = finalUrl.toString();
  return isLocalUrl ? finalUrlPath.replace(fake, "") : finalUrlPath;
}

async function resolve(response: Response) {
  try {
    return { data: await response.json(), status: response.status };
  } catch (e) {
    return { error: e, status: response.status };
  }
}

export const network = {
  async get(url: string, options: RequestOptions) {
    const { params, ...init } = options;
    return resolve(await fetch(urlWithParams(url, params), init));
  },
  async delete(url: string, options: RequestOptions) {
    const { params, data, ...init } = options;
    return resolve(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: data ? JSON.stringify(data) : undefined,
        method: "DELETE",
      })
    );
  },
  async post(url: string, body: RequestBody, options: RequestOptions) {
    const { params, ...init } = options;
    return resolve(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "POST",
      })
    );
  },
  async put(url: string, body: RequestBody, options: RequestOptions) {
    const { params, ...init } = options;
    return resolve(
      await fetch(urlWithParams(url, params), {
        ...init,
        body: JSON.stringify(body),
        method: "PUT",
      })
    );
  },
};
