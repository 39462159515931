export enum EntityType {
  ServiceProvider = "serviceProvider",
  Customer = "customer",
  System = "system",
  Account = "account",
}

export enum RoleType {
  Admin = "admin",
  User = "user",
  SuperAdmin = "superAdmin",
}

export interface Role {
  entityType: EntityType;
  entityId: number;
  role: RoleType;
}
