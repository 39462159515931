// import { Box, rem } from "@mantine/core";
// import { useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
// import { useUser } from "../../contexts/UserProfileContext.js";

export function NotificationsOverview() {
  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />
      hello
    </Layout>
  );
}
