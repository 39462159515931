// Layout.tsx

import React, { useEffect } from "react";
import { AppShell } from "@mantine/core";
import { Navbar } from "./Navbar/Navbar.js";
import { PennyGuideShell } from "./Guides/penny/GuideShell.js";
import { ConnectGuideShell } from "./Guides/connect/GuideShell.js";
import { RecipesGuideShell } from "./Guides/recipes/GuideShell.js";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import useCurrentPage from "../hooks/useCurrentPage.js";
import useFilteredCustomers from "../hooks/useFilteredCustomers.js";
import AppHeader from "./AppHeader.js";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { setActiveCustomer } = useActiveStates();
  const currentPage = useCurrentPage();
  const filteredCustomers = useFilteredCustomers();

  // Update activeCustomer when filteredCustomers change
  useEffect(() => {
    if (filteredCustomers.length > 0) {
      setActiveCustomer(filteredCustomers[0]);
    } else {
      setActiveCustomer(null);
    }
  }, [filteredCustomers]);

  return (
    <AppShell
      withBorder
      navbar={{
        width: 75,
        breakpoint: "sm",
        collapsed: { mobile: true, desktop: false },
      }}
      header={{ height: 70 }}
      aside={{ width: 500, breakpoint: "md", collapsed: { mobile: true } }}
    >
      <AppHeader />
      <AppShell.Navbar>
        <Navbar />
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
      {currentPage !== "home" && (
        <AppShell.Aside p={0}>
          {currentPage === "penny" && <PennyGuideShell />}
          {currentPage === "recipes" && <RecipesGuideShell />}
          {currentPage === "connect" && <ConnectGuideShell />}
        </AppShell.Aside>
      )}
    </AppShell>
  );
};
