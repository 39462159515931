import { css } from "../../helpers/css.js";

interface ClassNames {
  wrapper: string;
  title: string;
  description: string;
}

export default css<ClassNames>`
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px); /* Full viewport height */
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-8)
    );
  }

  .title {
    font-family: Greycliff CF, var(--mantine-font-family);
    text-align: left;
    font-size: 62px;
    font-weight: 900;
    line-height: 1.1;
    margin: 0;
    padding: 0;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: 42px;
      line-height: 1.2;
    }
  }

  .description {
    text-align: left;
    margin: var(--mantine-spacing-md) 0 var(--mantine-spacing-xl) 0;
    font-size: 24px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: 18px;
    }
  }
`;
