import { css } from "../../helpers/css.js";

export interface ClassNames {
  privacyOverrideTitle: string;
  privacyOverrideParagraph: string;
  title: string;
  sectionAsideHeaderBorder: string;
  sectionAsideFooterBorder: string;
}

export default css<ClassNames>`
  .privacyOverrideTitle {
    margin-top: rem(20);
    margin-bottom: rem(5);
  }

  .privacyOverrideParagraph {
    margin-bottom: rem(15);
  }

  .title {
    color: var(--mantine-color-black);
    font-size: rem(100);
    font-weight: 900;
    letter-spacing: rem(-2);
  }

  .sectionAsideHeaderBorder {
    max-height: rem(70);
    min-height: rem(70);
    border-bottom: 1px solid var(--app-shell-border-color);
  }

  .sectionAsideFooterBorder {
    max-height: rem(70);
    min-height: rem(70);
    border-top: 1px solid var(--app-shell-border-color);
  }
`;
