// src/contexts/ActiveStatesContext.tsx

import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { service_providers, accounts } from "../data/mock_data.js";
import {
  Account,
  ActiveStatesContextValue,
  Customer,
  ServiceProvider,
} from "../types/index.js";

export const ActiveStatesContext = createContext<
  ActiveStatesContextValue | undefined
>(undefined);

export const ActiveStatesProvider = ({ children }: { children: ReactNode }) => {
  const [activeAccount, setActiveAccount] = useState<Account | null>(
    accounts[0] || null
  );

  const [activeServiceProvider, setActiveServiceProvider] =
    useState<ServiceProvider | null>(null);

  const [activeCustomer, setActiveCustomer] = useState<Customer | null>(null);

  // Synchronize activeServiceProvider when activeAccount changes
  useEffect(() => {
    if (activeAccount?.providerId !== undefined) {
      // Find service provider associated with the active account
      const associatedProvider = service_providers.find(
        (provider) => provider.id === activeAccount.providerId
      );
      setActiveServiceProvider(associatedProvider || null);
    } else {
      // Account not associated with a service provider
      setActiveServiceProvider(null);
    }
  }, [activeAccount]);

  // When activeServiceProvider changes, ensure activeAccount is compatible
  useEffect(() => {
    if (activeServiceProvider) {
      // If activeAccount is not associated with the activeServiceProvider, update it
      if (activeAccount?.providerId !== activeServiceProvider.id) {
        const defaultAccount = accounts.find(
          (account) => account.providerId === activeServiceProvider.id
        );
        if (defaultAccount) {
          setActiveAccount(defaultAccount);
        } else {
          // No account associated with this service provider
          setActiveAccount(null);
        }
      }
    }
    // If activeServiceProvider is null, do not change activeAccount
  }, [activeServiceProvider]);

  return (
    <ActiveStatesContext.Provider
      value={{
        activeServiceProvider,
        setActiveServiceProvider,
        activeCustomer,
        setActiveCustomer,
        activeAccount,
        setActiveAccount,
      }}
    >
      {children}
    </ActiveStatesContext.Provider>
  );
};

export const useActiveStates = () => {
  const context = useContext(ActiveStatesContext);
  if (!context) {
    throw new Error(
      "useActiveStates must be used within an ActiveStatesProvider"
    );
  }
  return context;
};
