import { Button } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserProfileContext.js";

import { network } from "../../helpers/network.js";

const SlackButton = () => {
  const user = useUser();
  const [connected, setConnected] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      if (!user?.email) return;

      try {
        const response = await network.get(`/api/teams/fetch`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { email: user.email },
        });

        const currentUser = response.data.users.find(
          (u: any) => u.email === user.email
        );
        setPermissions(currentUser ? currentUser.permissions : []);
      } catch (error) {
        console.error("Error fetching user permissions:", error);
      }
    };

    fetchPermissions();
  }, [user]);

  useEffect(() => {
    const checkSlackConnection = async () => {
      try {
        const response = await network.get(`/api/slack/status`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { auth0_user_id: user?.sub },
        });
        setConnected(response.data.connected);
        if (response.data.connected) {
          const team_id = response.data.slackData.team_id;
          const teamResponse = await network.get(`/api/slack/team-name`, {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
            params: { team_id },
          });
          setTeamName(teamResponse.data.team_name);
        }
      } catch (error) {
        console.error("Error checking Slack connection:", error);
      }
    };

    checkSlackConnection();
  }, [user]);

  const handleConnect = async () => {
    await network.post(
      `/api/slack/store-user`,
      {
        auth0_user_id: user?.sub,
      },
      {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      }
    );
    // todo this should happen on the API
    // const slackOAuthUrl = `${env.APP_SLACK_AUTH_URL}?client_id=${env.APP_SLACK_CLIENT_ID}&scope=${env.APP_SLACK_SCOPE}&redirect_uri=/api/slack/callback&team=`;
    // window.location.href = slackOAuthUrl;
  };

  const hasCanAuthenticate =
    permissions && permissions.includes("canAuthenticate");

  if (connected) {
    return (
      <Button variant="subtle" color="white" leftSection={<IconCheck />}>
        Connected to the {teamName} workspace
      </Button>
    );
  }

  return (
    <Button
      onClick={handleConnect}
      variant="white"
      color="dark"
      disabled={!hasCanAuthenticate}
    >
      Connect
    </Button>
  );
};

export default SlackButton;
