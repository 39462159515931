// src/app/hooks/useAuth.ts

import { network } from "../helpers/network.js";

export interface Session {
  accessToken: string;
}

export interface Authentication {
  logOut(goToUrl: (url: string) => void): Promise<void>;
  readURLHash(goToUrl: (url: string) => void): void;
  sessionId?: number;
  session?: Session;
}

let authScope: Authentication;

function readSessionWithId(sessionId: number): Session | undefined {
  const data = localStorage.getItem(`session.${sessionId}`);
  return data ? JSON.parse(data) : undefined;
}

function setSessionWithId(id: number, session?: Session) {
  if (session) {
    localStorage.setItem(`session.${id}`, JSON.stringify(session));
  } else {
    localStorage.removeItem(`session.${id}`);
  }
}

export function deleteSessionList(): void {
  Array(10)
    .fill(undefined)
    .forEach((_, id) => deleteSessionWithId(id));
}

function readSessionList(): Array<Session | undefined> {
  return Array(10)
    .fill(undefined)
    .map((_, id) => readSessionWithId(id));
}

function getNextEmptySessionId() {
  const sessionList = readSessionList();
  const nextId = sessionList.findIndex((x) => !x);
  if (nextId === -1) {
    throw new Error(
      "All 10 session slots occupied, log out of an existing session before adding another one"
    );
  }
  return nextId;
}

function deleteSessionWithId(id: number) {
  setSessionWithId(id);
}

function redirectToAnySession(goToUrl: (url: string) => void) {
  const sessionList = readSessionList();
  const existingId = sessionList.findIndex((x) => x);
  if (existingId !== -1) {
    const session = readSessionWithId(existingId);
    if (session) {
      if (sessionStorage.getItem("sessionId") !== existingId.toString(10)) {
        sessionStorage.setItem("sessionId", existingId.toString(10));
        goToUrl("/");
        return;
      }
    }
  }
  goToUrl("/api/auth/login");
}

function getSessionIdWithAccessToken(accessToken: string) {
  const sessionList = readSessionList();
  const sessionId = sessionList.findIndex(
    (x) => x?.accessToken === accessToken
  );
  return sessionId === -1 ? undefined : sessionId;
}

function loadSessionIntoAuthScope() {
  const sessionId = parseInt(sessionStorage.getItem("sessionId") ?? "0", 10);
  const session = readSessionWithId(sessionId);
  authScope = {
    async logOut(goToUrl: (url: string) => void) {
      if (session) {
        const { accessToken } = session;
        await network.get("/api/auth/logout", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        deleteSessionWithId(sessionId);
      }
      redirectToAnySession(goToUrl);
    },
    readURLHash(goToUrl: (url: string) => void) {
      const newSessionId = getNextEmptySessionId();
      const [pageUrl, accessToken] = location.hash.split("#accessToken=");
      if (accessToken?.length > 0) {
        const existingSessionId = getSessionIdWithAccessToken(accessToken);
        if (typeof existingSessionId === "number") {
          sessionStorage.setItem("sessionId", existingSessionId.toString(10));
        } else {
          setSessionWithId(newSessionId, { accessToken });
          sessionStorage.setItem("sessionId", newSessionId.toString(10));
        }
        goToUrl(pageUrl);
      } else {
        if (!session) {
          redirectToAnySession(goToUrl);
        }
      }
    },
    sessionId,
    session,
  };
}

export function useAuth(): Authentication {
  if (!authScope) {
    loadSessionIntoAuthScope();
  }
  return authScope;
}
