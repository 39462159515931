// src/app/App.tsx

import { LoadingOverlay } from "@mantine/core";
import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css.js";
import { ActiveStatesProvider } from "./contexts/ActiveStatesContext.js";
import {
  Auth0UserProfile,
  UserProfileContext,
} from "./contexts/UserProfileContext.js";
import { network } from "./helpers/network.js";
import { useAuth } from "./hooks/useAuth.js";
import { ConnectOverview } from "./pages/connect/overview.js";
import { PennyOverview } from "./pages/penny/overview.js";
import { RecipeOverview } from "./pages/recipes/overview.js";
import { Welcome } from "./pages/welcome/index.js";
import { StoredAuthProfile } from "./types/index.js";
import { ProfileOverview } from "./pages/profile/overview.js";
import { NotificationsOverview } from "./pages/notifications/overview.js";

const router = createBrowserRouter(
  [
    { path: "/", Component: Welcome },
    { path: "/notifications/overview", Component: NotificationsOverview },
    { path: "/penny/overview", Component: PennyOverview },
    { path: "/recipes/overview", Component: RecipeOverview },
    { path: "/connect/overview", Component: ConnectOverview },
    { path: "/profile/overview", Component: ProfileOverview },
  ],
  {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
    },
  }
);

function App() {
  const { readURLHash, session, sessionId } = useAuth();

  useEffect(() => {
    readURLHash((url) => window.location.replace(url));
  }, [readURLHash]);

  const [profile, setProfile] = useState<Auth0UserProfile>();
  const [profileError, setProfileError] = useState<string | undefined>();

  useEffect(
    function () {
      async function load() {
        if (!session) {
          return;
        }
        const meResponse = await network.get("/api/auth/me", {
          headers: {
            Authorization: `Bearer ${session.accessToken}`,
          },
        });
        if (meResponse.data) {
          setProfile(meResponse.data);

          // Retrieve existing profiles from localStorage
          const existingProfiles: StoredAuthProfile[] = JSON.parse(
            localStorage.getItem("authProfiles") || "[]"
          );

          // Check if the profile already exists to avoid duplicates
          const profileExists = existingProfiles.some(
            (p) => p.profile.sub === meResponse.data.sub
          );

          if (!profileExists && typeof sessionId === "number") {
            // Add the new profile with its sessionId to the list
            existingProfiles.push({
              profile: meResponse.data,
              sessionId: sessionId,
            });
            localStorage.setItem(
              "authProfiles",
              JSON.stringify(existingProfiles)
            );
          }
        } else if (meResponse.error) {
          console.error("Failed to load user profile:", meResponse.error);
          setProfileError("Could not load user profile");
        }
      }
      load();
    },
    [session, sessionId]
  );

  if (profileError) {
    return <p>{profileError}</p>;
  }

  if (!profile) {
    return <LoadingOverlay />;
  }

  return (
    <UserProfileContext.Provider value={profile}>
      <ActiveStatesProvider>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </ActiveStatesProvider>
    </UserProfileContext.Provider>
  );
}

export default App;
