import { ActionIcon, AppShell, Group, Text, Title } from "@mantine/core";
import {
  IconArrowLeft,
  IconArrowRight,
  IconNumber1,
  IconNumber2,
  IconNumber3,
} from "@tabler/icons-react";
import { useState } from "react";

import classes from "../Guides.css.js";
import { GuideContent } from "./GuideContent.js";

export const RecipesGuideShell = () => {
  const [page, setPage] = useState(1);

  const goToNextPage = () => setPage((prev) => (prev < 3 ? prev + 1 : prev));
  const goToPreviousPage = () =>
    setPage((prev) => (prev > 1 ? prev - 1 : prev));

  return (
    <>
      <AppShell.Section p="md" className={classes.sectionAsideHeaderBorder}>
        <Group justify="space-between">
          <Title fw={700} order={3} mt={5}>
            {page === 1 && (
              <Group gap={10}>
                <IconNumber1 stroke="1.5" />
                How to Make Recipes
              </Group>
            )}
            {page === 2 && (
              <Group gap={10}>
                <IconNumber2 stroke="1.5" />
                What Penny Can Do
              </Group>
            )}
            {page === 3 && (
              <Group gap={10}>
                <IconNumber3 stroke="1.5" />
                Working with Penny
              </Group>
            )}
          </Title>
        </Group>
      </AppShell.Section>

      <GuideContent page={page} setInputAndCallWebhook={() => {}} />

      <AppShell.Section
        p="md"
        pt="lg"
        className={classes.sectionAsideFooterBorder}
      >
        <Group justify="space-between">
          <ActionIcon
            variant="filled"
            size="md"
            onClick={goToPreviousPage}
            disabled={page === 1}
            mb="xs"
          >
            <IconArrowLeft stroke={2} />
          </ActionIcon>

          <Text size="xs" fw={500}>
            {page === 1 && "Page 1 of 3"}
            {page === 2 && "Page 2 of 3"}
            {page === 3 && "Page 3 of 3"}
          </Text>

          <ActionIcon
            variant="filled"
            size="md"
            onClick={goToNextPage}
            disabled={page === 3}
            mb="xs"
          >
            <IconArrowRight stroke={2} />
          </ActionIcon>
        </Group>
      </AppShell.Section>
    </>
  );
};
