// src/app/components/Menus/ServiceProviderMenu.tsx

import {
  Avatar,
  Menu,
  MenuLabel,
  Tooltip,
  UnstyledButton,
  Text,
  Box,
  Stack,
  Button,
  Indicator,
  Badge,
} from "@mantine/core";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { isSuperAdmin } from "../../helpers/roleUtils.js";
import { getInitials } from "../../helpers/textUtils.js";

const ServiceProviderMenu = () => {
  const { activeServiceProvider, activeAccount } = useActiveStates();

  return (
    <Menu shadow="md" position="top" withArrow>
      <Menu.Target>
        <Tooltip
          label={activeServiceProvider?.name || "Service provider"}
          position="right"
          withArrow
          transitionProps={{ duration: 0 }}
        >
          <Indicator
            disabled={!activeServiceProvider?.notifications}
            inline
            size={15}
            offset={2}
            position="top-end"
            withBorder
          >
            <UnstyledButton>
              <Avatar variant="default" size={45} radius="sm">
                {activeServiceProvider?.initial ||
                  getInitials(activeAccount?.name || "Unknown Service")}
              </Avatar>
            </UnstyledButton>
          </Indicator>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        {activeServiceProvider && (
          <>
            <Box p="lg">
              <Stack gap={0}>
                <Avatar
                  variant="filled"
                  color={activeServiceProvider.primaryColor}
                  size={60}
                  radius="sm"
                  mx="auto"
                >
                  {activeServiceProvider.initial}
                </Avatar>
                <Text ta="center" fz="lg" fw={500} mt="md">
                  {activeServiceProvider.name}
                </Text>
                <Text ta="center" c="dimmed" fz="sm">
                  @{activeServiceProvider.slug}
                </Text>
                <Button size="xs" mt="sm" variant="light" fullWidth>
                  Edit Profile
                </Button>
              </Stack>
            </Box>
            <Menu.Divider />
            <MenuLabel>Service</MenuLabel>
            <Menu.Item>Dashboard</Menu.Item>
            <Menu.Item
              rightSection={
                <Badge>{activeServiceProvider.notifications}</Badge>
              }
            >
              Notifications
            </Menu.Item>
            <MenuLabel>Configure</MenuLabel>
            <Menu.Item>Manage Team</Menu.Item>
            <Menu.Item>Manage Customers</Menu.Item>
          </>
        )}

        {isSuperAdmin(activeAccount) && (
          <>
            <MenuLabel>Super Admin</MenuLabel>

            <Menu.Item>Add a service provider</Menu.Item>
            <Menu.Item>Manage service providers</Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default ServiceProviderMenu;
