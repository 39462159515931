import { css } from "../../helpers/css.js";

interface ClassNames {
  navbar: string;
  wrapper: string;
  aside: string;
  mainLinks: string;
  logout: string;
  main: string;
  mainLink: string;
  title: string;
  logo: string;
  link: string;
}

export default css<ClassNames>`
  .navbar {
    align-items: center;
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-6)
    );
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .wrapper {
    display: flex;
    flex: 1;
  }

  .aside {
    flex: 0 0 rem(75);
    background-color: var(--mantine-color-body);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: rem(1px) solid var(--app-shell-border-color);
    justify-content: space-between; /* Add this line */
    padding: var(--mantine-spacing-xs) 0;
  }

  .mainLinks {
    flex-grow: 1;
  }

  .logout {
    margin-bottom: var(--mantine-spacing-sm);
  }

  .main {
    flex: 1;
    background-color: light-dark(
      var(--mantine-color-white),
      var(--mantine-color-dark-6)
    );
  }

  .mainLink {
    width: calc(2.8125rem * var(--mantine-scale));
    height: calc(2.8125rem * var(--mantine-scale));
    border-radius: var(--mantine-radius-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));

    &:hover {
      background-color: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-gray-0)
      );
    }
    &[data-active] {
      background-color: light-dark(
        var(--mantine-color-gray-0),
        var(--mantine-color-gray-0)
      );
    }
  }

  .title {
    font-family: "Greycliff CF", var(--mantine-font-family);
    margin-bottom: var(--mantine-spacing-xl);
    background-color: var(--mantine-color-body);
    padding: var(--mantine-spacing-md);
    padding-top: rem(18px);
    height: rem(60px);
    border-bottom: rem(1px) solid
      light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  }

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: rem(60px);
    padding-top: var(--mantine-spacing-md);
    border-bottom: rem(1px) solid var(--app-shell-border-color);
    margin-bottom: var(--mantine-spacing-xs);
  }

  .link {
    display: block;
    text-decoration: none;
    border-top-right-radius: var(--mantine-radius-xl);
    border-bottom-right-radius: var(--mantine-radius-xl);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    padding: 0 var(--mantine-spacing-md);
    font-size: var(--mantine-font-size-sm);
    margin-right: var(--mantine-spacing-md);
    font-weight: 500;
    height: rem(44px);
    line-height: rem(44px);

    &:hover {
      background-color: light-dark(
        var(--mantine-color-slateBlue-0),
        var(--mantine-color-dark-5)
      );
      color: light-dark(var(--mantine-color-dark), var(--mantine-color-light));
    }

    &[data-active] {
      &,
      &:hover {
        border-left-color: var(--mantine-color-slateBlue-3);
        background-color: var(--mantine-color-black);
        color: var(--mantine-color-white);
      }
    }
  }
`;
