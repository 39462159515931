import { Button, Group } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../contexts/UserProfileContext.js";
import { network } from "../../helpers/network.js";

interface RecipeButtonProps {
  id: number;
}

const RecipeButton = ({ id }: RecipeButtonProps) => {
  const user = useUser();
  const [connected, setConnected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkRecipeStatus = async () => {
      if (!id) return;
      try {
        const response = await network.get(`/api/recipes/status`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
          params: { auth0_user_id: user?.sub, recipeId: id },
        });
        setConnected(response.data.connected);
      } catch (error) {
        console.error("Error checking recipe status:", error);
      }
    };

    checkRecipeStatus();
  }, [user, id]);

  const goToRecipe = async () => {
    navigate(`../recipes/recipe?id=${id}`);
  };

  if (connected) {
    return (
      <Group gap="sm">
        <Button variant="subtle" color="white" leftSection={<IconCheck />}>
          Activated
        </Button>
        <Button variant="white" onClick={goToRecipe}>
          See workflow
        </Button>
      </Group>
    );
  }

  return (
    <Button onClick={goToRecipe} variant="white">
      Get started
    </Button>
  );
};

export default RecipeButton;
