import { Box, rem } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Layout } from "../../components/Layout.js";
import { PageTitle } from "../../components/PageTitle.js";
import { useUser } from "../../contexts/UserProfileContext.js";

import ChatDisplay from "./ChatDisplay.js";
import ChatInput from "./ChatInput.js";

type ResponseData = {
  success: boolean;
  data?: { output: string };
  error?: string;
};

export function PennyOverview() {
  const user = useUser();
  const [input, setInput] = useState<string>("");
  const [output, setOutput] = useState<
    Array<{ type: "user" | "bot"; message: string }>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const sessionIdRef = useRef<string | null>(null);
  const [_, setPage] = useState(1);

  // Reference to the chat display container
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sessionIdRef.current) {
      const newSessionId = new Date().toISOString();
      sessionIdRef.current = newSessionId;
    }
  }, []);

  useEffect(() => {
    // Scroll to bottom when new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [output]); // This effect triggers whenever 'output' changes

  const callWebhook = async (inputValue: string) => {
    console.log(sessionIdRef);
    if (!sessionIdRef.current) return;

    setOutput((prevOutput) => [
      ...prevOutput,
      { type: "user", message: inputValue },
    ]);

    setLoading(true);
    try {
      const res = await fetch(`/api/penny/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId: sessionIdRef.current,
          chatInput: inputValue,
          auth0_user_id: user?.sub,
        }),
      });

      const data: ResponseData = await res.json();
      handleResponse(data);
    } catch (error) {
      handleResponse({ success: false, error: (error as Error).message });
    } finally {
      setLoading(false);
      setInput("");
    }
  };

  const handleResponse = (response: ResponseData) => {
    if (response.success && response.data) {
      setOutput((prevOutput) => [
        ...prevOutput,
        { type: "bot", message: response.data?.output || "" },
      ]);
    } else {
      setOutput((prevOutput) => [
        ...prevOutput,
        { type: "bot", message: `Error: ${response.error}` },
      ]);
    }
  };

  return (
    <Layout>
      <PageTitle title="Welcome to LedgerAI" />

      <Box
        style={{
          height: `calc(100vh - ${rem(70)})`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          p="md"
          ref={chatContainerRef} // Assign the ref to the container with the messages
          style={{
            flex: 1,
            overflowY: "auto",
            alignContent: "flex-end",
          }}
        >
          <ChatDisplay
            output={output}
            sessionId={sessionIdRef.current || ""}
            setPage={setPage}
          />
        </Box>
        <Box
          p="md"
          style={{
            height: "150px",
            borderTop: "1px solid var(--app-shell-border-color)",
          }}
        >
          <ChatInput
            input={input}
            setInput={setInput}
            onSend={() => callWebhook(input)}
            loading={loading}
          />
        </Box>
      </Box>
    </Layout>
  );
}
