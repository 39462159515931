export interface Recipe {
  id: number;
  background_image: string;
  category: string; // @todo - add enum support
  title: string;
  comingsoon: boolean;
}

export interface RecipesApiOverviewResponse {
  command: "SELECT";
  rowCount: 7;
  oid: null;
  rows: Array<Recipe>;
}
