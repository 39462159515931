import { ActionIcon, Indicator, rem, Stack, Tooltip } from "@mantine/core";
import {
  IconBell,
  IconBuilding,
  IconHome2,
  IconLink,
  IconTestPipe,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useActiveStates } from "../../contexts/ActiveStatesContext.js";
import { isSuperAdmin } from "../../helpers/roleUtils.js";
import ServiceProviderMenu from "../Menus/ServiceProviderMenu.js";
import classes from "./Navbar.css.js";
import { PennyIcon } from "./icons/Penny.js";

const mainLinksMockdata = [
  { icon: IconHome2, label: "Home", href: "/", indicator: false },
  {
    icon: IconBell,
    label: "Notifications",
    href: "/notifications/overview",
    indicator: true,
  },
  {
    icon: PennyIcon,
    label: "Penny",
    href: "/penny/overview",
    indicator: false,
  },
  {
    icon: IconTestPipe,
    label: "Recipes",
    href: "/recipes/overview",
    indicator: false,
  },
  {
    icon: IconLink,
    label: "Connections",
    href: "/connect/overview",
    indicator: false,
  },
  {
    icon: IconBuilding,
    label: "Customer Profile",
    href: "/profile/overview",
    indicator: false,
  },
];

export interface Sublinks {
  [key: string]: { label: string; href: string }[];
}

export function Navbar() {
  const { activeServiceProvider, activeAccount, activeCustomer } =
    useActiveStates();

  const location = useLocation();
  const [active, setActive] = useState("");
  // const [_, setActiveLink] = useState("");

  useEffect(() => {
    const path = location.pathname;

    // Determine main link based on the current path
    const mainLink = mainLinksMockdata.find((link) => {
      // Exact match for home page
      if (link.href === "/") {
        return path === "/";
      } else {
        return path.startsWith(link.href);
      }
    });

    if (mainLink) {
      setActive(mainLink.label);
    } else {
      setActive("");
    }
  }, [location.pathname]);

  const navigate = useNavigate();

  const mainLinks = mainLinksMockdata.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      transitionProps={{ duration: 0 }}
      key={link.label}
    >
      <ActionIcon
        variant="subtle"
        onClick={() => {
          setActive(link.label);
          navigate(link.href);
        }}
        className={classes.mainLink}
        data-active={link.label === active || undefined}
      >
        <Indicator
          radius="sm"
          label={activeCustomer?.notifications}
          inline
          size={20}
          offset={5}
          disabled={!link.indicator || !activeCustomer?.notifications}
          position="top-center"
          color={activeCustomer?.primaryColor}
        >
          <link.icon style={{ width: rem(27), height: rem(27) }} stroke={2} />
        </Indicator>
      </ActionIcon>
    </Tooltip>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={classes.aside}>
          <Stack justify="flex-start" gap={10} className={classes.mainLinks}>
            {mainLinks}
          </Stack>

          <Stack justify="flex-end" gap={0}>
            {(activeServiceProvider || isSuperAdmin(activeAccount)) && (
              <ServiceProviderMenu />
            )}
          </Stack>
        </div>
      </div>
    </nav>
  );
}
