import {
  Avatar,
  Badge,
  Button,
  Card,
  Group,
  Menu,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { IconCircleCheckFilled, IconDots } from "@tabler/icons-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  accounts as mockAccounts,
  service_providers,
} from "../../data/mock_data.js";
import { isSuperAdmin } from "../../helpers/roleUtils.js";
import { getInitials } from "../../helpers/textUtils.js";
import { deleteSessionList, useAuth } from "../../hooks/useAuth.js"; // Import useAuth hook
import {
  Account,
  ServiceProvider,
  StoredAuthProfile,
} from "../../types/index.js";
import { useActiveStates } from "../../contexts/ActiveStatesContext.js";

const AccountMenu = () => {
  const { activeServiceProvider } = useActiveStates();
  const { sessionId, logOut: _logOut } = useAuth(); // Get sessionId from useAuth

  const logOut = useCallback(async () => {
    const storedProfiles = localStorage.getItem("authProfiles");
    if (storedProfiles) {
      try {
        const parsedProfiles: StoredAuthProfile[] = JSON.parse(storedProfiles);
        localStorage.setItem(
          "authProfiles",
          JSON.stringify(
            parsedProfiles.filter((x) => x.sessionId !== sessionId)
          )
        );
      } catch (e) {
        console.error("Error removing stored profile", e);
      }
    }
    await _logOut((url) => window.location.replace(url));
  }, [_logOut]);

  const logOutAll = useCallback(async () => {
    try {
      // Remove all stored profiles from localStorage
      localStorage.removeItem("authProfiles");

      // Clear sessionStorage to remove session data
      sessionStorage.clear();

      // Delete all stored auth tokens
      deleteSessionList();

      // Call the logout function with a redirect
      await _logOut((url) => window.location.replace(url));
    } catch (error) {
      console.error("Error logging out of all accounts:", error);
    }
  }, [_logOut]);

  const [realAccounts, setRealAccounts] = useState<Account[]>([]);

  const activeAccount = useMemo(
    () => realAccounts.find((a) => a.sessionId === sessionId),
    [realAccounts, sessionId]
  );

  // Create a map for quick lookup of Service Providers by their ID
  const serviceProviderMap: { [key: number]: ServiceProvider } =
    React.useMemo(() => {
      const map: { [key: number]: ServiceProvider } = {};
      service_providers.forEach((sp) => {
        map[sp.id] = sp;
      });
      return map;
    }, []);

  // Create a map from mockAccounts by 'sub' for easy lookup
  const mockAccountsMap: { [sub: string]: Account } = React.useMemo(() => {
    const map: { [sub: string]: Account } = {};
    mockAccounts.forEach((account) => {
      map[account.sub] = account;
    });
    return map;
  }, [mockAccounts]);

  // Fetch real accounts from localStorage and merge with mock data
  useEffect(() => {
    const storedProfiles = localStorage.getItem("authProfiles");
    if (storedProfiles) {
      try {
        const parsedProfiles: StoredAuthProfile[] = JSON.parse(storedProfiles);
        const mappedAccounts: Account[] = parsedProfiles.map(
          ({ profile, sessionId }) => {
            const mockAccount = mockAccountsMap[profile.sub];
            return {
              sub: profile.sub, // Unique identifier
              name: profile.name || profile.nickname || "Unknown",
              nickname: profile.nickname || "",
              email: profile.email,
              picture: profile.picture,
              initial: getInitials(profile.name || profile.nickname || "U"),
              providerId: mockAccount?.providerId, // Use mock data's providerId if available
              roles: mockAccount?.roles || [], // Use mock data's roles
              notifications: mockAccount?.notifications || 0, // Use mock data's notifications
              sessionId: sessionId, // Associate sessionId with the account
            };
          }
        );
        setRealAccounts(mappedAccounts);
      } catch (error) {
        console.error("Failed to parse authProfiles from localStorage:", error);
      }
    }
  }, [mockAccountsMap]);

  // Exclude mock accounts that are present in realAccounts to avoid duplication
  const realAccountSubs = new Set(realAccounts.map((account) => account.sub));

  const filteredMockAccounts = React.useMemo(() => {
    return mockAccounts.filter((account) => !realAccountSubs.has(account.sub));
  }, [mockAccounts, realAccountSubs]);

  /**
   * Combines mock accounts and real accounts.
   */
  const combinedAccounts: Account[] = React.useMemo(() => {
    return [/*...filteredMockAccounts, */ ...realAccounts];
  }, [filteredMockAccounts, realAccounts]);

  /**
   * Handles switching between accounts by setting the appropriate session ID
   * and updating the ActiveStatesContext.
   *
   * @param account - The account to switch to.
   */
  const handleSwitch = (account: Account) => {
    if (account.sessionId === undefined) {
      throw new Error("account has no session");
    }
    // Real account: set sessionId in sessionStorage
    sessionStorage.setItem("sessionId", account.sessionId.toString(10));
    window.location.replace("/");
  };

  /**
   * Renders each account as a Menu.Item with appropriate Avatar styling.
   *
   * @param accountsToRender - Array of accounts to render.
   * @returns Array of JSX elements representing each account.
   */
  const renderAccounts = (accountsToRender: Account[]) =>
    accountsToRender.map((account) => {
      // Retrieve the associated Service Provider, if any
      const serviceProvider = account.providerId
        ? serviceProviderMap[account.providerId]
        : null;

      return (
        <Menu.Item
          key={account.sub}
          onClick={() => handleSwitch(account)}
          leftSection={
            <Avatar
              alt={account.name}
              radius="xl"
              size={45}
              src={account.picture}
            >
              {getInitials(account.name)}
            </Avatar>
          }
          rightSection={
            account.sub === activeAccount?.sub ? (
              <IconCircleCheckFilled color="#00ba7c" size={19} />
            ) : (
              account.notifications > 0 && (
                <Badge>
                  <Group gap={0}>{account.notifications}</Group>
                </Badge>
              )
            )
          }
        >
          <Stack mr="xl" gap={0}>
            <Group>
              <Text fw={700}>{account.name}</Text>
              {serviceProvider && (
                <Badge variant="light" size="xs" radius="sm">
                  {account.roles[0]?.role || "User"}
                </Badge>
              )}
            </Group>

            <Text color="dimmed" size="sm" fw={500}>
              {account.email}
            </Text>
          </Stack>
        </Menu.Item>
      );
    });

  /**
   * Determines the Avatar content and styling for the Menu.Target.
   *
   * @returns JSX Element representing the Avatar.
   */
  const renderMenuTargetAvatar = () => {
    if (!activeAccount) {
      // Handle case when there's no active account
      return (
        <Avatar radius="xl" size={40} color="gray">
          A
        </Avatar>
      );
    }

    const serviceProvider = activeAccount.providerId
      ? serviceProviderMap[activeAccount.providerId]
      : null;

    return (
      <Avatar
        alt={activeAccount.name}
        radius="xl"
        size={40}
        color={serviceProvider ? serviceProvider.primaryColor : "black"}
        src={activeAccount.picture}
      >
        {getInitials(activeAccount.name)}
      </Avatar>
    );
  };

  return (
    <Menu shadow="md">
      <Menu.Target>
        <UnstyledButton>
          <Group gap={5}>
            {renderMenuTargetAvatar()}
            <Stack gap={0} mt="6" ml="xs" mr="xs">
              <Text size="sm" fw={700}>
                {activeAccount?.name || "No Account"}
              </Text>
              <Text size="xs">{activeAccount?.email || "No Email"}</Text>
            </Stack>
            <IconDots size={20} />
          </Group>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown pt={0}>
        <Card>
          <Card.Section h={90} bg={activeServiceProvider?.primaryColor}>
            <Text ta="center" mt="lg" c="white" size="sm" fw={600}>
              {activeServiceProvider?.domain}
            </Text>
          </Card.Section>
          <Avatar
            src={activeAccount?.picture}
            size={80}
            radius={80}
            mx="auto"
            mt={-30}
            style={{ border: "2px solid white" }}
          />
          <Text ta="center" fz="lg" fw={700} mt="sm">
            {activeAccount?.name}
          </Text>
          <Text ta="center" fz="sm" fw={500} c="dimmed">
            {activeAccount?.email}
          </Text>
          <Group justify="center">
            <Button size="xs" mt="sm" variant="light">
              Edit Profile
            </Button>
            <Button onClick={logOut} size="xs" mt="sm" variant="light">
              Log out
            </Button>
          </Group>
        </Card>
        <Menu.Divider />
        <Menu.Label>Other accounts</Menu.Label>

        {/* Other Accounts (excluding the active account) */}
        {combinedAccounts.some(
          (account) =>
            account.providerId !== undefined &&
            account.sub !== activeAccount?.sub
        ) && (
          <>
            {renderAccounts(
              combinedAccounts.filter(
                (account) =>
                  account.providerId !== undefined &&
                  account.sub !== activeAccount?.sub
              )
            )}
          </>
        )}

        {/* Free Accounts (excluding the active account) */}
        {combinedAccounts.some(
          (account) =>
            account.providerId === undefined &&
            !isSuperAdmin(account) &&
            account.sub !== activeAccount?.sub
        ) && (
          <>
            {renderAccounts(
              combinedAccounts.filter(
                (account) =>
                  account.providerId === undefined &&
                  !isSuperAdmin(account) &&
                  account.sub !== activeAccount?.sub
              )
            )}
          </>
        )}

        {/* Super Admin Accounts (excluding the active account) */}
        {combinedAccounts.some(
          (account) =>
            isSuperAdmin(account) && account.sub !== activeAccount?.sub
        ) && (
          <>
            {renderAccounts(
              combinedAccounts.filter(
                (account) =>
                  isSuperAdmin(account) && account.sub !== activeAccount?.sub
              )
            )}
            <Menu.Divider />
          </>
        )}

        <Menu.Item component="a" href="/api/auth/login">
          Add new account
        </Menu.Item>
        <Menu.Item onClick={logOutAll}>Log out of all accounts</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AccountMenu;
