import { css } from "../../helpers/css.js";

interface ClassNames {
  userBubble: string;
  botBubble: string;
  chatWrapperInner: string;
  infoText: string;
  textarea: string;
}

export default css<ClassNames>`
  .userBubble {
    align-self: flex-end;
    background-color: var(--mantine-color-gray-0);
    padding: rem(10) rem(20);
    border-radius: var(--mantine-radius-sm);
    max-width: 70%;
  }

  .botBubble {
    align-self: flex-start;
  }

  .chatWrapperInner {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 100%;
    margin: var(--mantine-spacing-xl) 0;
  }
  .infoText {
    text-align: center;
  }

  .textarea {
    width: 100%;
    margin-bottom: var(--mantine-spacing-xs);
  }
`;
