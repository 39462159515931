import { useLocation } from "react-router-dom";

const useCurrentPage = () => {
  const location = useLocation();
  const path = location.pathname;

  if (path.startsWith("/penny")) {
    return "penny";
  } else if (path.startsWith("/recipes")) {
    return "recipes";
  } else if (path.startsWith("/connect")) {
    return "connect";
  } else {
    return "home";
  }
};

export default useCurrentPage;
