import { useMemo } from "react";
import { useActiveStates } from "../contexts/ActiveStatesContext.js";
import { customers } from "../data/mock_data.js";

const useFilteredCustomers = () => {
  const { activeServiceProvider, activeAccount } = useActiveStates();

  const filteredCustomers = useMemo(() => {
    if (activeServiceProvider) {
      return customers.filter(
        (customer) => customer.providerId === activeServiceProvider.id
      );
    } else if (activeAccount?.customerId) {
      return customers.filter(
        (customer) => customer.id === activeAccount.customerId
      );
    } else {
      return [];
    }
  }, [activeServiceProvider, activeAccount]);

  return filteredCustomers;
};

export default useFilteredCustomers;
