// components/AppHeader.tsx
import { AppShell, Group } from "@mantine/core";
import CustomerMenu from "./Menus/CustomerMenu.js";
import AccountMenu from "./Menus/AccountMenu.js";

const AppHeader = () => (
  <AppShell.Header>
    <Group justify="space-between" py="xs" px="md">
      <CustomerMenu />
      <Group>
        <AccountMenu />
      </Group>
    </Group>
  </AppShell.Header>
);

export default AppHeader;
