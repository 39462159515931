export interface CardData {
  id: number;
  backgroundImage: string;
  category: string;
  title: string;
  span: {
    base: number;
    md: number;
    lg: number;
  };
  link: string;
  comingsoon: boolean;
}
