// src/app/contexts/UserProfileContext.tsx

import { createContext, useContext } from "react";

export type Auth0UserId = string & { auth_0_userid: never };
export type EmailAddress = Lowercase<string> & {
  email: never;
};

export interface Auth0UserProfile {
  email_verified: boolean;
  email: EmailAddress;
  name: string;
  nickname: string;
  picture: string;
  sub: Auth0UserId;
  updated_at: string;
}

export const UserProfileContext = createContext<Auth0UserProfile>(
  undefined as unknown as Auth0UserProfile
);

export function useUser() {
  return useContext(UserProfileContext);
}
