import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Popover,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconCopy,
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUp,
  IconThumbUpFilled,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

import classes from "./Chat.css.js";
import MarkdownRenderer from "./MarkdownRenderer.js";

type ChatDisplayProps = {
  output: Array<{ type: "user" | "bot"; message: string }>;
  sessionId: string;
  setPage: (page: number) => void;
};

export default function ChatDisplay({ output, sessionId }: ChatDisplayProps) {
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const [feedback, setFeedback] = useState("");
  const [clickedIcons, setClickedIcons] = useState<{
    [key: number]: { copy: boolean; thumbUp: boolean; thumbDown: boolean };
  }>({});

  // Ref for the message container
  const messageContainerRef = useRef<HTMLDivElement>(null);

  // Function to handle API call to submit feedback
  const submitFeedback = async (
    _: number,
    originalMessage: string,
    feedbackType: "positive" | "negative"
  ) => {
    const first100Chars = originalMessage.slice(0, 100);
    try {
      const res = await fetch(`/api/penny/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId,
          originalMessage: first100Chars,
          feedback,
          feedbackType,
        }),
      });

      const data = await res.json();
      console.log("Feedback response:", data);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }

    setOpenedIndex(null); // Close popover after submission
    setFeedback(""); // Clear the feedback text
  };

  // Function to handle thumbs up feedback submission without text
  const submitThumbUpFeedback = async (_: number, originalMessage: string) => {
    try {
      const res = await fetch(`/api/penny/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessionId,
          originalMessage: originalMessage.slice(0, 100),
          feedback: "Thumbs up feedback",
          feedbackType: "positive", // Positive feedback for thumbs up
        }),
      });

      const data = await res.json();
      console.log("Thumbs up feedback response:", data);
    } catch (error) {
      console.error("Error submitting thumbs up feedback:", error);
    }
  };

  const handleIconClick = (
    index: number,
    iconType: "copy" | "thumbUp" | "thumbDown",
    text?: string
  ) => {
    if (iconType === "copy" && text) {
      navigator.clipboard.writeText(text).then(() => {
        setClickedIcons((prevState) => ({
          ...prevState,
          [index]: {
            ...prevState[index],
            [iconType]: true,
          },
        }));
      });
    } else if (iconType === "thumbUp") {
      // Handle thumbs up feedback submission
      submitThumbUpFeedback(index, output[index].message);
      setClickedIcons((prevState) => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          thumbUp: true,
          thumbDown: false, // Disable thumbs down when thumbs up is clicked
        },
      }));
    } else if (iconType === "thumbDown") {
      // Handle thumbs down feedback, open popover
      setClickedIcons((prevState) => ({
        ...prevState,
        [index]: {
          ...prevState[index],
          thumbDown: true,
          thumbUp: false, // Disable thumbs up when thumbs down is clicked
        },
      }));
      setOpenedIndex(index); // Open the popover for feedback
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [output]); // This effect ensures scrolling when new content is added

  return (
    <>
      {output.map((item, index) => (
        <div key={index} className={classes.chatWrapperInner}>
          <div
            className={
              item.type === "user" ? classes.userBubble : classes.botBubble
            }
          >
            <MarkdownRenderer content={item.message} />
          </div>

          {item.type !== "user" && index > 6 && (
            <Group gap={5} mt="md" mb="xl">
              <Tooltip
                label={clickedIcons[index]?.copy ? "Copied!" : "Copy"}
                withArrow
                position="bottom"
              >
                <ActionIcon
                  variant="light"
                  mr={5}
                  onClick={() => handleIconClick(index, "copy", item.message)}
                >
                  {clickedIcons[index]?.copy ? (
                    <IconCheck size={18} stroke={2} />
                  ) : (
                    <IconCopy size={18} stroke={2} />
                  )}
                </ActionIcon>
              </Tooltip>

              <Tooltip
                label={
                  clickedIcons[index]?.thumbUp
                    ? "Awesome, glad I was helpful!"
                    : "Thanks, Penny! This was helpful"
                }
                withArrow
                position="bottom"
              >
                <ActionIcon
                  variant="light"
                  onClick={() => handleIconClick(index, "thumbUp")}
                  disabled={clickedIcons[index]?.thumbDown} // Disable thumbs up if thumbs down is clicked
                >
                  {clickedIcons[index]?.thumbUp ? (
                    <IconThumbUpFilled size={18} stroke={2} />
                  ) : (
                    <IconThumbUp size={18} stroke={2} />
                  )}
                </ActionIcon>
              </Tooltip>

              <Popover
                width={300}
                trapFocus
                position="bottom"
                withArrow
                shadow="md"
                opened={openedIndex === index} // Only open popover if this index matches
                onClose={() => setOpenedIndex(null)}
              >
                <Popover.Target>
                  <Tooltip
                    label={
                      clickedIcons[index]?.thumbDown
                        ? "Oof, let's dig in"
                        : "This wasn't helpful"
                    }
                    withArrow
                    position="bottom"
                  >
                    <ActionIcon
                      variant="light"
                      onClick={() => {
                        handleIconClick(index, "thumbDown");
                      }}
                      disabled={clickedIcons[index]?.thumbUp} // Disable thumbs down if thumbs up is clicked
                    >
                      {clickedIcons[index]?.thumbDown ? (
                        <IconThumbDownFilled size={18} stroke={2} />
                      ) : (
                        <IconThumbDown size={18} stroke={2} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Popover.Target>
                <Popover.Dropdown>
                  <Group justify="space-between">
                    <Text size="sm" fw={500}>
                      Say more...
                    </Text>
                    <ActionIcon
                      onClick={() => setOpenedIndex(null)}
                      variant="transparent"
                    >
                      <IconX size={18} stroke={2} />
                    </ActionIcon>
                  </Group>
                  <Textarea
                    data-autofocus
                    placeholder="I wish it would have said..."
                    size="xs"
                    value={feedback}
                    onChange={(e) => setFeedback(e.currentTarget.value)} // Capture feedback text
                  />
                  <Button
                    size="xs"
                    mt="xs"
                    mb="sm"
                    onClick={
                      () => submitFeedback(index, item.message, "negative") // Negative feedback for thumbs down
                    }
                  >
                    Share with Penny
                  </Button>
                </Popover.Dropdown>
              </Popover>
            </Group>
          )}
          {index === 1 && (
            <Text size="xs" c="dimmed" mt="sm">
              Just one quick note: by continuing, you agree to our{" "}
              <Anchor
                href="https://ledger.ai/privacy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </Anchor>
              .
            </Text>
          )}
        </div>
      ))}
    </>
  );
}
