export const PennyIcon = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_562_124)">
      <path
        d="M16.9468 26.3419C17.0765 24.6091 17.2528 23.7388 17.4759 22.0156"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.35596 19.7881C5.46035 23.5022 13.1979 26.8449 16.966 26.3582"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.1356 13.291C10.0374 13.8353 10.1368 14.2196 10.258 14.7591C10.3792 15.2985 10.6174 15.8652 11.129 16.0743C12.117 16.4773 12.2096 16.1991 12.6649 16.064"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.6401 18.9086C20.7693 18.7401 20.8383 18.8141 20.9675 18.6455C21.0304 18.6698 21.0014 18.7638 21.0117 18.8304C21.0269 18.9304 21.1427 18.9765 21.2414 18.9989C22.1057 19.1953 21.9166 19.1935 22.7809 19.0007"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9929 17.528C23.8142 17.5948 23.6857 17.6656 24.0263 17.453C24.3669 17.2402 24.5906 16.8504 24.6021 16.4492C24.6136 16.0473 24.4293 15.6704 24.2657 15.3031C24.087 14.9018 23.9281 14.4908 23.7905 14.0739C23.7178 13.8545 23.59 13.6272 23.6039 13.3963C23.6202 13.1205 23.8111 12.8665 23.9306 12.6168C24.4293 11.5725 24.6221 10.4167 24.4845 9.2669C24.3464 8.11651 23.7354 7.32313 22.7832 6.68066"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.4263 17.6875C13.4954 18.2408 13.5682 18.8057 13.8105 19.3081C14.1834 20.0797 14.9136 20.6173 15.6713 21.018C17.4036 21.935 19.4256 22.2945 21.3681 22.0308C21.7244 21.9956 21.8887 21.9471 22.1027 21.8005C22.6106 21.7519 22.6336 20.6816 22.3688 20.1258C22.6124 19.8767 23.0355 19.7373 23.0663 19.39C23.0955 19.0663 23.2355 18.8499 22.9409 18.7136"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8789 9.71431C18.2729 9.76825 18.6753 9.77553 19.0699 9.72946C19.4645 9.6834 19.8669 9.58036 20.2372 9.41611C21.398 8.90153 22.258 7.76145 22.4343 6.50439C22.6107 5.24734 21.9749 4.15696 21.0003 3.34357C20.9161 3.27326 20.8082 3.20235 20.704 3.23629"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4724 15.7119C10.7748 17.1302 10.7027 18.6066 10.4354 20.0321C10.1681 21.7577 9.50321 22.8324 8.4292 23.8069"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3.24023 19.7649C4.60821 18.3898 2.9487 16.0374 3.92573 14.6695C5.05126 13.0936 5.661 12.8536 5.0834 11.982C4.30818 10.8135 4.38758 10.0364 4.35486 8.66117C4.23 3.42869 11.3802 -0.0667034 15.2634 0.698804C16.2254 0.888514 16.9012 1.26066 17.9558 1.22248C21.0173 1.11217 21.8476 0.616981 23.3829 3.57476C26.1218 6.30828 25.8096 8.3266 25.6757 9.04181C25.5418 9.75701 25.417 9.96974 24.6768 10.6595"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.00928 14.2529C9.14382 13.8759 9.36324 13.5305 9.6578 13.2589C9.79175 13.1352 9.94146 13.025 10.113 12.9619C10.6391 12.768 11.2088 13.0547 11.7991 13.3275C12.161 13.5844 12.6393 13.8408 13.1628 13.9135C14.0945 14.0438 15.0813 13.508 15.4794 12.6553"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.5166 12.2814C14.8015 12.3838 15.1002 12.4371 15.4016 12.445C16.024 12.462 16.6555 12.2868 17.1846 11.9547C17.9702 11.7619 18.532 10.6467 18.7829 9.75391"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.9653 13.2921C20.6424 12.8878 21.1224 12.749 21.8945 12.5908"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.0466 13.4877C20.8659 13.2756 20.8671 13.3059 20.6865 13.0938"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.8638 11.1767C20.3256 11.0119 20.8281 11.0076 20.9923 11.0198C21.799 11.0561 21.8881 11.1531 22.3627 11.3356"
        stroke="black"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5.32845 11.2296C3.25438 7.78764 6.91099 3.37946 7.06675 9.19743C6.98493 7.49065 6.24125 5.43233 7.87166 4.46196C8.47534 4.30741 8.89901 6.04753 8.77354 7.09669C8.72688 5.64628 9.33661 3.2449 10.9198 3.05338C11.0367 3.94434 11.2015 4.22316 11.1519 5.12018C10.9592 3.24188 12.2968 2.1709 13.3497 2.1709C13.7521 2.1709 13.4114 4.43044 13.4114 4.43044C17.9887 4.55712 14.4078 7.78522 12.4817 6.83122C16.3207 8.73256 11.2446 8.85621 10.053 8.20585C14.5745 10.6721 10.1148 10.6975 8.17653 9.97931C12.7151 10.5266 10.1318 14.1881 5.32786 11.2285"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_562_124">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
