import { Account, Role, RoleType, EntityType } from "../types/index.js";

export const hasRole = (
  account: Account | null,
  requiredRole: RoleType,
  entityType: EntityType,
  entityId?: number
): boolean => {
  if (!account || !account.roles) return false;

  return account.roles.some((role: Role) => {
    const roleMatches = role.role === requiredRole;
    const entityTypeMatches = role.entityType === entityType;
    const entityIdMatches =
      entityId !== undefined ? role.entityId === entityId : true;

    return roleMatches && entityTypeMatches && entityIdMatches;
  });
};

export const isSuperAdmin = (account: Account | null): boolean => {
  return hasRole(account, RoleType.SuperAdmin, EntityType.System);
};

export const isServiceProviderAdmin = (
  account: Account | null,
  serviceProviderId: number
): boolean => {
  return hasRole(
    account,
    RoleType.Admin,
    EntityType.ServiceProvider,
    serviceProviderId
  );
};

export const isCustomerAdmin = (
  account: Account | null,
  customerId: number
): boolean => {
  return hasRole(account, RoleType.Admin, EntityType.Customer, customerId);
};

export const isFreeAccount = (account: Account | null): boolean => {
  return hasRole(account, RoleType.Admin, EntityType.Account);
};
