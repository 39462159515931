// src/app/components/Modals/CustomerModal.tsx

import {
  Avatar,
  Button,
  Card,
  ColorInput,
  FileInput,
  Group,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";
import { IconAt, IconUpload } from "@tabler/icons-react";
import { Customer, ServiceProvider } from "../../types/index.js";
import { useState } from "react";

interface CustomerModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (customer: Partial<Customer>) => void;
  activeServiceProvider?: ServiceProvider | null;
}

const CustomerModal = ({
  opened,
  onClose,
  onSubmit,
  activeServiceProvider,
}: CustomerModalProps) => {
  const [newCustomer, setNewCustomer] = useState<Partial<Customer>>({
    name: "",
    domain: "",
    primaryColor: "#000000",
    location: "",
    avatar: "",
    initial: "",
  });

  // Handle input change
  const handleInputChange = (field: keyof Customer, value: string | number) => {
    setNewCustomer((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = () => {
    if (
      !newCustomer.name ||
      !newCustomer.domain ||
      !newCustomer.slug ||
      !newCustomer.primaryColor ||
      !newCustomer.location ||
      !newCustomer.avatar
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    onSubmit(newCustomer);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} centered>
      {activeServiceProvider && (
        <Card pt={0}>
          <Avatar
            variant="filled"
            color={activeServiceProvider?.primaryColor}
            size={60}
            radius="sm"
            mx="auto"
          >
            {activeServiceProvider?.initial}
          </Avatar>
          <Text ta="center" fz="lg" fw={500} mt="md">
            Add a new customer
          </Text>
        </Card>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <TextInput
          label="Customer Name"
          placeholder="e.g., Jack's Kitchen"
          required
          value={newCustomer.name}
          onChange={(e) => handleInputChange("name", e.currentTarget.value)}
          mb="sm"
        />
        <TextInput
          leftSection={<IconAt size={16} />}
          label="Initials"
          placeholder="e.g., JK"
          required
          value={newCustomer.initial}
          onChange={(e) => handleInputChange("initial", e.currentTarget.value)}
          mb="sm"
        />
        <TextInput
          label="Domain"
          placeholder="e.g., jackskitchen.com"
          required
          value={newCustomer.domain}
          onChange={(e) => handleInputChange("domain", e.currentTarget.value)}
          mb="sm"
        />
        <TextInput
          leftSection={<IconAt size={16} />}
          label="Handle"
          placeholder="e.g., jackskitchen"
          required
          value={newCustomer.slug}
          onChange={(e) => handleInputChange("slug", e.currentTarget.value)}
          mb="sm"
        />
        <ColorInput
          label="Primary Color"
          placeholder="Pick a color"
          required
          value={newCustomer.primaryColor}
          onChange={(value) =>
            handleInputChange("primaryColor", value || "#000000")
          }
          mb="sm"
        />
        <TextInput
          label="Location"
          placeholder="e.g., Salt Lake City, UT"
          required
          value={newCustomer.location}
          onChange={(e) => handleInputChange("location", e.currentTarget.value)}
          mb="sm"
        />
        <FileInput
          label="Avatar Image"
          placeholder="Select an image"
          leftSection={<IconUpload size={14} />}
          accept="image/*"
          mb="sm"
        />
        <Group mt="md">
          <Button
            type="submit"
            fullWidth
            color={activeServiceProvider?.primaryColor}
          >
            Add Customer
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default CustomerModal;
