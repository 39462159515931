import { Auth0UserProfile } from "../contexts/UserProfileContext.js";
import { Role } from "./role.js";

export interface ServiceProvider {
  id: number;
  name: string;
  domain: string;
  primaryColor: string;
  avatar?: string;
  initial: string;
  slug: string;
  notifications: number;
}

export interface Account {
  sub: string;
  picture: string;
  name: string;
  nickname: string;
  email: string;
  customerId?: number;
  providerId?: number; // Added providerId to link account to service provider
  roles: Role[];
  notifications: number;
  sessionId?: number;
}

export interface StoredAuthProfile {
  profile: Auth0UserProfile;
  sessionId: number;
}

export interface Customer {
  id: number;
  avatar: string;
  name: string;
  domain: string;
  slug: string;
  primaryColor: string;
  initial: string;
  providerId: number;
  location: string;
  notifications: number;
}

export interface ActiveStatesContextValue {
  activeServiceProvider: ServiceProvider | null;
  setActiveServiceProvider: (provider: ServiceProvider | null) => void;
  activeCustomer: Customer | null;
  setActiveCustomer: (customer: Customer | null) => void;
  activeAccount: Account | null;
  setActiveAccount: (account: Account | null) => void;
}
