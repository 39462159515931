import { css } from "../../helpers/css.js";

interface ClassNames {
  card: string;
  title: string;
  category: string;
}

export default css<ClassNames>`
  .card {
    height: rem(440px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
  }

  .title {
    font-family: Greycliff CF, var(--mantine-font-family);
    font-weight: 900;
    color: var(--mantine-color-white);
    line-height: 1.2;
    font-size: rem(32px);
    margin-top: var(--mantine-spacing-xs);
  }

  .category {
    color: var(--mantine-color-white);
    opacity: 0.7;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
